'use strict';

var base = require('../product/base');
var focusHelper = require('base/components/focus');
var afterpayWidget = require('../product/afterpayWidget');
var catchHelper = require('catch/catch/helper/catchHelper');
var util = require('../util/util');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * check if bonus product in cart and update if cart updated
 * @param {Object} data - AJAX response from the server
 */
function updateCartWithBonusProducts(data) {
    if ($('.bonus-product-line-item').length > 0 || data.hasBonusProduct) {
        location.reload();
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            if (data.resources.numberOfItems[0] === '1' && data.resources.numberOfItems[1] === ' ') {
                // eslint-disable-next-line no-param-reassign
                data.resources.numberOfItems = '1 item';
            }
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * Updates rewards on cart page
 * @param {Object} data cart model data
 */
function updateRewards(data) {
    var $rewardsBtn = $('.js-apply-rewards');
    var $rewardsSummaryLine = $('.js-cart-summary-rewards');
    var $rewardsOrderAmtMsg = $('.js-min-order-amt-rewards-mgs');
    var $employeeDiscountMsg = $('.js-employee-discount-mgs');

    // Reward limit reached
    if (data.loyaltyAwardsUsed && data.loyaltyAwardsUsed.rewardLimitReached) {
        $rewardsBtn.addClass('d-none');
        $rewardsSummaryLine.addClass('d-none');
        $rewardsOrderAmtMsg.addClass('d-none');
        // Reward applied
    } else if (data.totals.rewardsDiscountTotal) {
        $rewardsSummaryLine.removeClass('d-none');
        $rewardsBtn.addClass('d-none');
        $rewardsOrderAmtMsg.addClass('d-none');
        // has employee discount
    } else if (data.totals.adjustedMerchandizeTotalNetPrice <= 5 || data.rewardLimitReached) {
        $rewardsBtn.addClass('d-none');
        $rewardsSummaryLine.addClass('d-none');
        $rewardsOrderAmtMsg.removeClass('d-none');
    } else if (data.hasEmployeeDiscount) {
        $employeeDiscountMsg.removeClass('d-none');
    } else {
        // Reward available
        $rewardsSummaryLine.addClass('d-none');
        $rewardsBtn.removeClass('d-none');
        $rewardsOrderAmtMsg.addClass('d-none');
        $employeeDiscountMsg.addClass('d-none');
    }
    if (data.totals.rewardsDiscountTotal) {
        $('.js-cart-summary-rewards-value').text(data.totals.rewardsDiscountTotal.formatted);
    }
    $('.js-rewards-available').text(data.loyaltyAwardsAvailable);
}
/**
 * Updates applied shipping discount messages
 * @param {Object} data cart model data
 */
function updateShippingMessages(data) {
    var $shippingDiscountContainer = $('.js-shipping-discounts');

    $shippingDiscountContainer.empty();

    if (data.shippingDiscounts.length > 0) {
        $shippingDiscountContainer.removeClass('d-none');
        data.shippingDiscounts.forEach(function (shippingDiscount) {
            var $shippingDiscount = $('<div/>')
                .addClass('single-shipping-discount text-dark-gray')
                .text(shippingDiscount.discountMsg);
            $shippingDiscountContainer.append($shippingDiscount);
        });
    } else {
        $shippingDiscountContainer.addClass('d-none');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    if (data.error) {
        return;
    }

    if (data.resources.numberOfItems[0] === '1' && data.resources.numberOfItems[1] === ' ') {
        // eslint-disable-next-line no-param-reassign
        data.resources.numberOfItems = '1 item';
    }
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(!data.totals.freeShipping ? data.totals.totalShippingCost : data.totals.freeShipping);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.js-grand-total-amount').empty().append(data.totals.grandTotal);
    $('.js-sticky-cart-total').text(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.shipments) {
        var shippingCost = '';
        data.shipments[0].shippingMethods.forEach(function (item, index) {
            shippingCost = (data.totals.shippingLevelDiscountTotal.formatted === item.shippingCost) ? data.totals.freeShipping : item.shippingCost;
            $('.shipping-method-list .shipping-cost:eq(' + index + ')').empty().append(shippingCost);
        });
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0 && !data.totals.freeShipping) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            if ($('#checkout-main').length > 0) {
                var discountsHtml = data.totals.discountsHtml;
                discountsHtml = discountsHtml.replace(/remove-coupon/g, 'checkout-remove-coupon').replace(/#removeCouponModal/g, '#checkoutRemoveCouponModal');
                $('.checkout-coupons-and-promos').empty().append(discountsHtml);
            } else {
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            }
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.adjusted-unit-price-value' + item.UUID).empty();
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
    afterpayWidget.getWidget(null, data.totals.grandTotal.replace(/[NZA$]/g, ''), 'cart-afterpay-message');
    $('.cart-pp-msg').attr('data-pp-amount', data.totals.grandTotal.replace(/[NZA$]/g, ''));
    updateShippingMessages(data);
    updateRewards(data);
    // Catch code start
    catchHelper.updateCartWidgets(data.totals);
    if (data.totals.grandTotalValue > 4999) {
        $('.js-catch-method').addClass('d-none');
    } else if (!$('.js-giftcard-remove').length) {
        $('.js-catch-method').removeClass('d-none');
    }
    // Catch code end
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '<div class="modal-body p-0">'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} redirect - redirect url
 * @param {Object} event - event
 */
function confirmDelete(actionUrl, productID, productName, uuid, redirect, event) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('redirect', redirect);

    if (event) {
        $deleteConfirmBtn.data('pname', event.data('name'));
        $deleteConfirmBtn.data('brand', event.data('product_brand'));
        $deleteConfirmBtn.data('price', event.data('price'));
        $deleteConfirmBtn.data('quantity', event.data('quantity'));
        $deleteConfirmBtn.data('category', event.data('product_category'));
        $deleteConfirmBtn.data('product_color', event.data('product_color'));
        $deleteConfirmBtn.data('bopis', event.data('bopis'));
        $deleteConfirmBtn.data('discount', event.data('discount'));
        $deleteConfirmBtn.data('product_sku', event.data('pid'));
        $deleteConfirmBtn.data('master-id', event.data('master-id'));
        $deleteConfirmBtn.data('order_type', event.data('order_type'));
        $deleteConfirmBtn.data('markdown_type', event.data('markdown_type'));
    }

    $productToRemoveSpan.empty().append(productName);
}

/**
 * TrueFit matching items
*/
function trueFitMatch() {
    var masterId = [];
    var results = [];
    var object = {};

    $('body').find('.product-info').each(function () {
        masterId.push($(this).attr('data-master-id'));
    });

    masterId.forEach(function (item) {
        if (!object[item]) object[item] = 0;
        object[item] += 1;
    });

    Object.keys(object).forEach(function (key) {
        if (object[key] >= 2) {
            results.push(key);
        }
    });

    var bannerObj = [];
    $('body').find('.product-info').each(function () {
        var newObj = {
            styleId: $(this).attr('data-master-id'),
            styleName: $(this).attr('data-name'),
            size: $(this).attr('data-size')
        };
        bannerObj.push(newObj);
    });

    if (results.length > 0) {
        results.forEach(function (item) {
            masterId = item;
            var color;
            var size;
            var userID = $('body').find('.tfc-fitrec-size-sampling').data('userid');
            $('body').find('.product-info').each(function () {
                if ($(this).attr('data-master-id') === masterId) {
                    var sizeAttr = $(this).find('[data-attribute="size"]');
                    var colorAttr = $(this).find('[data-attribute="color"]');
                    size = sizeAttr.attr('data-value');
                    color = colorAttr.attr('data-value');
                    var truefitButton = $('<div class="tfc-fitrec-product" data-userid data-styleid data-colorid data-availablesizes data-locale="en_US"></div>');
                    if (!$(this).find('.tfc-fitrec-product').length) $(truefitButton).insertAfter($(this).find('#truefitCart'));
                    var $truefitDiv = $(this).find('.tfc-fitrec-product');
                    $truefitDiv.attr('data-styleid', masterId);
                    $truefitDiv.attr('data-colorid', color);
                    $truefitDiv.attr('data-availablesizes', 'NoSizesAvailable');
                    $truefitDiv.attr('data-userid', userID);
                    $(this).find('.truefitCart').show();
                }
                window.tfcapi('sizeSampling', bannerObj);
            });
            // true fit banner when duplicate product with different size
        });
        window.tfcapi('calculate');
    }
    // when true fit recommends a size and move the true fit button to the wrong size product
    window.tfcapi('event', 'tfc-fitrec-product', 'success', function (context) {
        var $id = context.fitRecommendation.id;
        var $size = context.fitRecommendation.size;
        $('body').find('.tfc-fitrec-product').each(function () {
            if ($(this).attr('data-styleid') === $id) {
                var sizeAttr = $(this).parent().find('[data-attribute="size"]');
                var correctSize = sizeAttr.attr('data-value');
                var $this = $(this);
                if ($size.match(correctSize) !== null) {
                    $('body').find('.product-info').each(function () {
                        var wrongSizeAttr = $(this).find('[data-attribute="size"');
                        var wrongSize = wrongSizeAttr.attr('data-value');
                        if ($(this).attr('data-master-id') === $id && $size.match(wrongSize) === null) $this.insertAfter($(this).find(sizeAttr).parent());
                    });
                }
            }
        });
    });
}

/**
 * Handle out of stock items
 */
function handleOutOfStock() {
    if ($('.out-of-stock').length > 0 && $('.out-of-stock').parents('.row .no-gutters').length > 0) {
        var outOfStockProductsTotal = 0;
        $('.out-of-stock').each(function () {
            var value = $(this).parents('.row .no-gutters').find('.line-item-total-price-amount').attr('data-price');
            if (value) {
                outOfStockProductsTotal += Number(value);
            }
        });

        if ($('.sub-total').length > 0) {
            var subtotal = Number($('.sub-total').html().replace('$', ''));
            subtotal -= outOfStockProductsTotal;
            if (!isNaN(subtotal)) {
                $('.sub-total').html('$' + subtotal.toFixed(2));
            }
        }

        if ($('.grand-total').length > 0) {
            var grandTotal = Number($('.grand-total').html().replace('$', ''));
            grandTotal -= outOfStockProductsTotal;
            if (!isNaN(grandTotal)) {
                $('.grand-total').html('$' + grandTotal.toFixed(2));
            }
        }
    }
}

/**
 * updates the shipping method radio buttons within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order/basket model
 */
function updateShippingMethods(shipping) {
    var uuidEl = $('input[value=' + shipping.UUID + ']');

    if (uuidEl && uuidEl.length > 0) {
        $.each(uuidEl, function (shipmentIndex, el) {
            var form = el.form;
            if (!form) return;

            var $shippingMethodList = $('.shipping-method-list');

            if ($shippingMethodList && $shippingMethodList.length > 0) {
                $shippingMethodList.empty();
                var shippingMethods = shipping.applicableShippingMethods;
                var selected = shipping.selectedShippingMethod || shipping.applicableShippingMethods[0];
                var shippingMethodFormID = form.name + '_shippingAddress_shippingMethodID';
                //
                // Create the new rows for each shipping method
                //
                $.each(shippingMethods, function (methodIndex, shippingMethod) {
                    var tmpl = $('#shipping-method-template').clone();
                    // set input
                    $('input', tmpl)
                        .prop('id', 'shippingMethod-' + shippingMethod.ID + '-' + shipping.UUID)
                        .prop('name', shippingMethodFormID)
                        .prop('value', shippingMethod.ID)
                        .attr('checked', shippingMethod.ID === selected.ID);

                    $('label', tmpl)
                        .prop('for', 'shippingMethod-' + shippingMethod.ID + '-' + shipping.UUID);
                    // set shipping method name
                    if (shippingMethod.ID === '005' || !shippingMethod.promiseDate) {
                        $('.display-name', tmpl).text(shippingMethod.displayName);
                    } else {
                        $('.display-name', tmpl).text('Estimated Delivery: ' + shippingMethod.promiseDate);
                    }
                    // set or hide arrival time
                    if (shippingMethod.estimatedArrivalTime) {
                        $('.arrival-time', tmpl)
                            .text('(' + shippingMethod.estimatedArrivalTime + ')')
                            .show();
                    } else {
                        $('.arrival-time', tmpl)
                            .text(shippingMethod.shippingMethodDescrition)
                            .show();
                    }
                    // set shipping cost
                    if (shippingMethod.shippingCost === 'Free') {
                        $('.shipping-cost', tmpl).text(shippingMethod.shippingCost).css('color', 'red');
                    } else {
                        $('.shipping-cost', tmpl).text(shippingMethod.shippingCost).css('color', 'black');
                    }
                    $shippingMethodList.append(tmpl.html());
                });
            }
        });
    }

    $('body').trigger('shipping:updateShippingMethods', { shipping: shipping });
}

/**
 * Handle out mobile checkout drawer
 */
function stickyCheckoutDrawer() {
    var $checkoutDrawer = $('.sticky-add-to-cart');
    var $footerContent = $('#footercontent');
    var cachedWidth = $(window).width();

    var initCheckoutDrawer = function () {
        if ($checkoutDrawer.css('position') === 'fixed') {
            $footerContent.css({
                'margin-bottom': $('.sticky-add-to-cart').outerHeight()
            });
        } else {
            $footerContent.css({
                'margin-bottom': 0
            });
        }
    };

    initCheckoutDrawer();

    util.smartResize(function () {
        var newWidth = $(window).width();
        if (newWidth !== cachedWidth) {
            cachedWidth = newWidth;
            initCheckoutDrawer();
        }
    });
}

module.exports = function () {
    // truefit
    (function myFunction(i, tries, duration) {
        if (window.tfcapi) {
            trueFitMatch();
        } else if (i < tries) {
            setTimeout(function () { myFunction(i + 1, tries); }, duration);
        }
    }(0, 10, 500));

    // Save for Later
    $('body').on('click', '.save-for-later-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var masterID = $(this).data('master-id');
        var quantity = $(this).data('quantity');
        var productRemoval = $('body').find('.product-info[data-master-id=' + masterID + ']');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var selectedOptions = {
            product_id: $(this).data('pid'),
            pname: $(this).data('pname'),
            brand: $(this).data('brand'),
            price: $(this).data('price'),
            Quantity: $(this).data('quantity'),
            category: $(this).data('category'),
            product_color: $(this).data('product_color'),
            bopis: $(this).data('bopis'),
            discount: $(this).data('discount'),
            product_sku: $(this).data('product_sku'),
            markdown_type: $(this).data('markdown_type'),
            order_type: $(this).data('order_type')
        };

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    if (data.errorMessage) {
                        var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                            'fade show" role="alert">' +
                            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                            '<span aria-hidden="true">&times;</span>' +
                            '</button>' + data.errorMessage + '</div>';

                        $('.cart-error').append(errorHtml);
                    } else if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    }
                } else {
                    $('.cart-error').html('');
                    $('body').trigger('event:RemoveFromCart', selectedOptions);
                    if (data.basket.items.length === 0) {
                        $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                        $('.minicart-quantity').empty().append(data.basket.numItems);
                        $('.minicart-link').attr({
                            'aria-label': data.basket.resources.minicartCountOfItems,
                            title: data.basket.resources.minicartCountOfItems
                        });
                        $('.minicart .popover').empty();
                        $('.minicart .popover').removeClass('show');
                        $('body').removeClass('modal-open');
                        $('html').removeClass('veiled');

                        if ($('.cart-page-container').length > 0 || $('.bfx-checkout-container').length > 0) {
                            document.location.reload(true);
                        }

                        $.spinner().stop();
                    } else {
                        $('.uuid-' + uuid).remove();

                        if (!data.basket.hasBonusProduct) {
                            $('.bonus-product').remove();
                        }

                        $('.product-summary-items-count').empty().html(data.basket.numItems); // update number of cart lineitems

                        if ($('#checkout-main').length > 0) {
                            var discountsHtml = data.basket.totals.discountsHtml;
                            discountsHtml = discountsHtml.replace(/remove-coupon/g, 'checkout-remove-coupon').replace(/#removeCouponModal/g, '#checkoutRemoveCouponModal');
                            $('.checkout-coupons-and-promos').empty().append(discountsHtml);
                        } else {
                            $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                        }
                        updateCartTotals(data.basket);
                        $('.shipping-total-cost').text(data.basket.totals.totalShippingCost);
                        $('.shipping-method-price').text(data.basket.totals.totalShippingCost);
                        $('.js-loyalty-points').text(Math.ceil(data.basket.totals.subTotal.replace('$', '')));
                        updateApproachingDiscounts(data.basket.approachingDiscounts);
                        $('body').trigger('setShippingMethodSelection', data.basket);

                        validateBasket(data.basket);
                        // True Fit Banner Update
                        (function myFunction(q, tries, duration) {
                            if (window.tfcapi) {
                                if (productRemoval.find('.truefitCart').is(':visible') && $('body').find('.trueFitBanner').is(':visible')) {
                                    var bannerObj = [];
                                    $('body').find('.product-info').each(function () {
                                        if ($(this).attr('data-master-id') === masterID) $(this).find('.truefitCart').hide();
                                        var newObj = {
                                            styleId: $(this).attr('data-master-id'),
                                            styleName: $(this).attr('data-name'),
                                            size: $(this).attr('data-size')
                                        };
                                        bannerObj.push(newObj);
                                    });
                                    window.tfcapi('sizeSampling', bannerObj);
                                    window.tfcapi('calculate');
                                }
                            } else if (q < tries) {
                                setTimeout(function () { myFunction(q + 1, tries); }, duration);
                            }
                        }(0, 10, 500));

                        $.spinner().stop();
                    }

                    $('body').trigger('cart:update');
                    // Catch code start
                    var miniCartWidget = $('.minicart .catch-pdp-widget');
                    var cartCalloutWidget = $('.cart-page .catch-pdp-widget');
                    if (data.isIneligibleProductInBasket) {
                        catchHelper.hideWidget(miniCartWidget);
                        catchHelper.hideWidget(cartCalloutWidget);
                    } else {
                        catchHelper.showWidget(miniCartWidget);
                        catchHelper.showWidget(cartCalloutWidget);
                    }
                    // Catch code end
                    document.location.reload(true);
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    // Remove a product from Save For Later basket
    $('body').on('click', '.remove-save-for-later-btn', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        var url = appendToUrl(actionUrl, urlParams);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.cart-error').html('');
                $('.uuid-' + uuid).remove();
                document.location.reload(true);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    // Add back to cart from Save For Later basket
    $('body').on('click', '.save-for-later-add-to-cart-btn', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var quantity = $(this).data('quantity');
        var uuid = $(this).data('uuid');

        var urlParams = {
            quantity: quantity,
            pid: productID,
            uuid: uuid
        };

        var url = appendToUrl(actionUrl, urlParams);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.cart-error').html('');
                $('.uuid-' + uuid).remove();
                document.location.reload(true);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var redirect = $(this).data('redirect');

        confirmDelete(actionUrl, productID, productName, uuid, redirect, $(this));
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var masterID = $(this).data('master-id');
        var productRemoval = $('body').find('.product-info[data-master-id=' + masterID + ']');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var selectedOptions = {
            product_id: $(this).data('pid'),
            pname: $(this).data('pname'),
            brand: $(this).data('brand'),
            price: $(this).data('price'),
            Quantity: $(this).data('quantity'),
            category: $(this).data('category'),
            product_color: $(this).data('product_color'),
            bopis: $(this).data('bopis'),
            discount: $(this).data('discount'),
            product_sku: $(this).data('product_sku'),
            markdown_type: $(this).data('markdown_type'),
            order_type: $(this).data('order_type')
        };

        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.cart-error').html('');
                $('body').trigger('event:RemoveFromCart', selectedOptions);
                if ($('#checkout-main').length > 0 && data.basket.items.length === 0) {
                    window.location.href = $('.remove-btn.remove-product').attr('data-redirect');
                }

                if (data.basket.items.length === 0) {
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');

                    if ($('.cart-page-container').length > 0 || $('.bfx-checkout-container').length > 0) {
                        document.location.reload(true);
                    }
                    $.spinner().stop();
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();

                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }

                    $('.product-summary-items-count').empty().html(data.basket.numItems); // update number of cart lineitems

                    if ($('#checkout-main').length > 0) {
                        var discountsHtml = data.basket.totals.discountsHtml;
                        discountsHtml = discountsHtml.replace(/remove-coupon/g, 'checkout-remove-coupon').replace(/#removeCouponModal/g, '#checkoutRemoveCouponModal');
                        $('.checkout-coupons-and-promos').empty().append(discountsHtml);
                    } else {
                        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    }
                    updateCartTotals(data.basket);
                    $('.shipping-total-cost').text(data.basket.totals.totalShippingCost);
                    $('.shipping-method-price').text(data.basket.totals.totalShippingCost);
                    $('.js-loyalty-points').text(Math.ceil(data.basket.totals.subTotal.replace('$', '')));
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    data.order.shipping.forEach(function (shipping) {
                        updateShippingMethods(shipping);
                    });
                    $('body').trigger('setShippingMethodSelection', data.basket);

                    validateBasket(data.basket);
                    // True Fit Banner Update
                    (function myFunction(q, tries, duration) {
                        if (window.tfcapi) {
                            if (productRemoval.find('.truefitCart').is(':visible') && $('body').find('.trueFitBanner').is(':visible')) {
                                var bannerObj = [];
                                $('body').find('.product-info').each(function () {
                                    if ($(this).attr('data-master-id') === masterID) $(this).find('.truefitCart').hide();
                                    var newObj = {
                                        styleId: $(this).attr('data-master-id'),
                                        styleName: $(this).attr('data-name'),
                                        size: $(this).attr('data-size')
                                    };
                                    bannerObj.push(newObj);
                                });
                                window.tfcapi('sizeSampling', bannerObj);
                                window.tfcapi('calculate');
                            }
                        } else if (q < tries) {
                            setTimeout(function () { myFunction(q + 1, tries); }, duration);
                        }
                    }(0, 10, 500));
                    $.spinner().stop();
                }

                $('body').trigger('cart:update');
                // Catch code start
                var miniCartWidget = $('.minicart .catch-pdp-widget');
                var cartCalloutWidget = $('.cart-page .catch-pdp-widget');
                if (data.isIneligibleProductInBasket) {
                    catchHelper.hideWidget(miniCartWidget);
                    catchHelper.hideWidget(cartCalloutWidget);
                } else {
                    catchHelper.showWidget(miniCartWidget);
                    catchHelper.showWidget(cartCalloutWidget);
                }
                // Catch code end
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();


        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.cart-error').html('');

                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                updateCartWithBonusProducts(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update');

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.cart-error').html('');
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.coupon-code-field').on('focus', function () {
        $('button.promo-code-btn').css('background-color', '#111');
    });

    $('.coupon-code-field').on('focusout', function () {
        var field = $(this);
        if (!field.val()) $('button.promo-code-btn').css('background-color', '#949494');
    });

    $('body').on('submit', '.promo-code-form', function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        $('.coupon-onlyonce-message').hide();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty().hide();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.coupon-onlyonce-message').hide();
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('.coupon-error-message').show();
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.cart-error').html('');
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    afterpayWidget.getWidget(null, data.totals.grandTotal.replace(/[NZA$]/g, ''), 'cart-afterpay-message');

                    $('body').trigger('promotion:success', data);

                    // check if only one coupon can be applied msg
                    if ($('.coupon-applied').length && $('.coupon-not-applied').length) {
                        $('.promo-code-form .form-control').addClass('is-invalid');
                        $('.promo-code-form .form-control').attr('aria-describedby', 'onlyOnceCouponCode');
                        $('.coupon-onlyonce-message').show();
                    }
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        if (couponCode === 'Loyalty Reward') {
            $.ajax({
                url: $('.js-loyalty-remove').data('action')
            }).done(function (data) {
                updateCartTotals(data);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            });
        } else {
            url = appendToUrl(url, urlParams);

            $('body > .modal-backdrop').remove();

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('.cart-error').html('');
                    $('.coupon-uuid-' + uuid).remove();
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    afterpayWidget.getWidget(null, data.totals.grandTotal.replace(/[NZA$]/g, ''), 'cart-afterpay-message');
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });


    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.edit.product-decoration-item', function (e) {
        e.preventDefault();
        var url = $(this).attr('data-action');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                getModalHtmlElement();
                fillModalElement(url);
            }
        });
    });

    $('body').on('click', '.cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
        var maxWidth = 0;
        var $sizeSwatches = $('.select-size').find('.attribute-tile');
        $sizeSwatches.each(function () {
            if ($(this).outerWidth() > maxWidth) { maxWidth = $(this).outerWidth(); }
        });
        $sizeSwatches.css('width', maxWidth);
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
        $('#editProductModal').remove();
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };


        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('.cart-error').html('');
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');
                    // True Fit Banner Update
                    (function myFunction(q, tries, duration) {
                        if (window.tfcapi) {
                            trueFitMatch();
                        } else if (q < tries) {
                            setTimeout(function () { myFunction(q + 1, tries); }, duration);
                        }
                    }(0, 10, 500));
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('.js-apply-rewards').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            url: $(this).data('action')
        }).done(function (data) {
            var urlParams = {};
            if (data.error) {
                urlParams = {
                    applyCouponErrorMessage: data.errorMessage
                };

                var redirectUrl = data.redirectUrl;
                redirectUrl += (redirectUrl.indexOf('?') !== -1 ? '&' : '?') +
                    Object.keys(urlParams).map(function (key) {
                        return key + '=' + encodeURIComponent(urlParams[key]);
                    }).join('&');
                window.location.href = redirectUrl;
            }
            updateCartTotals(data);
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        });
    });

    $(document).ready(function () {
        if ($('#bfx-cc-flag-link img').attr('alt') === 'United States') {
            $('.checkout-btn').removeClass('bfx-checkout');
        } else {
            $('.checkout-btn').addClass('bfx-checkout');
        }
        handleOutOfStock();
    });

    // applepay button
    if ($('.apple-pay-cart.btn').css('display') === 'none') {
        $('.paypal-content').addClass('col-12').removeClass('pl-2');
    }

    // To handle "Show More" / "Show Less" functionality
    $(document).ready(function () {
        var toggleMobileBtn = document.getElementById('saved-later-header');
        var itemsContainer = document.getElementById('cart-items-container');
        var items = itemsContainer.querySelectorAll('.saved-product-info');
        var isVisible = false;

        toggleMobileBtn.addEventListener('click', function () {
            isVisible = !isVisible;
            items.forEach(function (item, index) {
                // if (index >= 3) {
                item.style.display = isVisible ? 'block' : 'none';
                // }
            });
            toggleMobileBtn.classList.toggle('collapsed');
        });
    });

    // mobile sticky checkout drawer
    stickyCheckoutDrawer();

    base.selectAttribute();
    base.selectAttributeTile();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
